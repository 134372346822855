package tta.destinigo.talktoastro.feature_authentication.data.request

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RegisterRequest(
    @SerialName("full_name")val fullName:String,
    @SerialName("refer_code")val referCode:String?,
)
