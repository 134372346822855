package tta.destinigo.talktoastro.feature_authentication.di

import org.koin.dsl.module
import tta.destinigo.talktoastro.feature_authentication.data.AuthApiService
import tta.destinigo.talktoastro.feature_authentication.data.RegisterApiService
import tta.destinigo.talktoastro.feature_authentication.domain.OtpValidationUseCases
import tta.destinigo.talktoastro.feature_authentication.domain.RegistrationUserUseCases

val authorizationModule = module {
    single { AuthApiService() }
    single { OtpValidationUseCases(get()) }
    single { RegistrationUserUseCases(get()) }
//    single { ExpertApi() }
    single { RegisterApiService() }
}