package tta.destinigo.talktoastro.feature_authentication.domain

import co.touchlab.kermit.Logger
import tta.destinigo.talktoastro.feature_authentication.data.RegisterApiService
import tta.destinigo.talktoastro.feature_authentication.data.request.RegisterRequest
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tta.destinigo.talktoastro.shared.local.UserInfoProvider
import tta.destinigo.talktoastro.shared.remote.Resources

class RegistrationUserUseCases(
    val registerApiService: RegisterApiService
) {

    suspend fun onRegisterUser(request: RegisterRequest): Flow<Resources<Boolean>> {
        return flow {
            registerApiService.registerUser(request).collect {

                when (it) {
                    is Resources.Error -> {
                        Logger.d { "onRegisterUser ${it.msg}" }
                        emit(Resources.Error(it.msg))
                    }
                    is Resources.Loading -> emit(Resources.Loading(it.isLoading))
                    is Resources.Success -> {
                        Logger.d { "onRegisterUser ${it.data}" }
                        it.data?.let { user ->
                            UserInfoProvider.setFullName(user.fullName)
                            UserInfoProvider.setTotalCoin(user.joiningUserCoin ?: 0)
                            UserInfoProvider.setIsLogin(true)
                            UserInfoProvider.setImage(user.profileImg ?: "")
                            UserInfoProvider.setId(user.userId)
                        }
                    }
                }
            }
        }

    }
}