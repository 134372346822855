package tta.destinigo.talktoastro.feature_authentication.persentation.register_page

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import tta.destinigo.talktoastro.feature_authentication.data.RegisterApiService
import tta.destinigo.talktoastro.feature_authentication.data.response.RegisterUserResponseData
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import tta.destinigo.talktoastro.feature_authentication.data.request.RegisterRequest
import tta.destinigo.talktoastro.feature_authentication.domain.RegistrationUserUseCases
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.collectLatest
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import tta.destinigo.talktoastro.shared.local.UserInfoProvider
import tta.destinigo.talktoastro.shared.remote.Resources

class RegisterComponent(
    private val context: ComponentContext,
    private val onRegistration:()  -> Unit
):ComponentContext by context, KoinComponent {


    private val userRegistrationUseCases: RegistrationUserUseCases by inject()
    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main)
    private val registerApiService: RegisterApiService by inject()
    private val _state = MutableStateFlow<Resources<Boolean>>(Resources.Loading(false))
    val state get() = _state

    init {
        lifecycle.doOnStart {
            Logger.d { "Register page Component onStart" }

        }

        lifecycle.doOnDestroy {
            Logger.d { "Register page  Component onDestroy" }
            coroutineScope.cancel() // Cancel all coroutines when the component is destroyed
        }
    }

    private fun onSuccess(data: RegisterUserResponseData) {
        coroutineScope.launch {
            data.let {
                UserInfoProvider.setFullName(data.fullName)
                UserInfoProvider.setTotalCoin(data.joiningUserCoin ?: 0)
                UserInfoProvider.setIsLogin(true)
                UserInfoProvider.setImage(data.profileImg ?: "")
                UserInfoProvider.setId(data.userId)
            }

            delay(1000)
            onRegistration.invoke()
        }
    }

    suspend fun onRegisterUser(userName: String, referalCode:String?){
        val request = RegisterRequest(
            fullName = userName,
            referCode = referalCode
        )

        registerApiService.registerUser(request).collectLatest {
            when(it){
                is Resources.Error -> {
                    Logger.d { "RegisterComponent ${it.msg}" }
                    _state.value = Resources.Error(it.msg)
                }
                is Resources.Loading -> {
                    Logger.d { "RegisterComponent ${it.isLoading}" }
                    _state.value = Resources.Loading(it.isLoading)
                }
                is Resources.Success -> {
                    Logger.d { "RegisterComponent ${it.data}" }
                    _state.value = Resources.Success(true)
                    //delay(1000)
                    onSuccess(it.data!!)

                }
            }
        }
    }
}